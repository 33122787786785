<template>
  <div class="game-image">
    <BaseImage
      :src="stage.imageUrl"
      :is-loading="isLoading"
      lazy-mode
      contain
    />
    <div class="game-image__gradient" />
    <div class="game-image__controls">
      <button
        :disabled="isLoading"
        @click="nextStep"
      >
        <span>{{ stage.continueBtn }}</span>
      </button>
    </div>
  </div>
</template>
<script>
import BaseImage from '@/components/Image/BaseImage';

export default {
  name: 'GameImage',
  components: {
    BaseImage,
  },
  props: {
    stage: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    nextStep() {
      this.$emit('completed');
    },
  },
};
</script>
<style lang="scss" scoped>
.game-image {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: calc(100vh - 100px);
  height: calc(100svh - 100px);
  justify-content: center;
  align-items: center;
  background: #000;

  @include media-min($lg) {
    height: calc(100vh - 95px);
    height: calc(100svh - 95px);
  }

  &__gradient {
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    height: 180px;
    opacity: 0.7;
    pointer-events: none;
    transition: all 0.5s ease;
  }

  &__controls {
    position: absolute;
    bottom: 45px;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    button {
      padding: 1.4rem 5rem;
      border-radius: 0.4rem;
      background: var(--primary-color);
      outline: none;
      transition: border 0.2s, background 0.3s, box-shadow 0.2s ease-out;

      &:hover {
        box-shadow: 0px 4px 16px 0px rgb(176, 190, 197, 0.24);
      }
    }
    span {
      color: $white;
      line-height: 1.6rem;
      font-size: 1.4rem;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}
</style>
